import $ from 'jquery';
$(document).ready(function () {
  if ($('.sidebar-faq').length >= 1) {
    $('.sidebar-faq .tabs a').click(function (e) {
      e.preventDefault()
      $(this).closest('.tabs').find('li').removeClass('active')
      $(this).parent().addClass('active')

      $(this).closest('.box').find('.tab-wrapper').addClass('hidden')
      $(this).closest('.box').find('#tab-wrapper-' + $(this).data('tab')).removeClass('hidden')
    })
  }
})
