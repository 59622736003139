import $ from 'jquery';
$(document).ready(function () {
  if ($('.reference').length >= 1) {
    $(window).on('resize.reference', function () {
      if ($(this).width() >= 768) {
        $('.reference .cell').each(function () {
          $(this).css('background-image', 'url(' + $(this).data('desktop-bg') + ')')
        })
      } else {
        $('.reference .cell').attr('style', null)
      }
    }).trigger('resize.reference')

    var referenceCarousels = []
    $('.reference').each(function () {
      if ($(this).find('.cell').length > 1) {
        var referenceCarousel = $(this).find('.carousel').flickity({
          prevNextButtons: false,
          pageDots: true
        })

        referenceCarousels.push(referenceCarousel)
      }
    })
  }
})
