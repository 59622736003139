import $ from 'jquery';

$(document).ready(function () {
  $(window).on('resize.main-nav', function () {
    var bottomHeight = $(window).height() - $('#header').height() - $('#main-nav .main-nav-top').height()

    $('#main-nav .main-nav-bottom').css('height', bottomHeight)
  }).trigger('resize.main-nav')

  $('#main-nav .nav.first-level a').click(function (e) {
    var navToToggle = $(this).data('nav-to-toggle')

    if ($('#' + navToToggle).length >= 1) {
      e.preventDefault()

      if ($(window).width() < 1024) {
        $(this).closest('.nav.first-level').transition({
          opacity: 0,
          x: '-100%'
        }, 300, function () {
          $(this).css('display', 'none')
          $('#' + navToToggle).css({
            opacity: 0,
            display: 'block',
            x: '100%'
          }).transition({
            opacity: 1,
            x: 0
          }, 300)
        })
      } else {
        $('.nav.visible').removeClass('visible')
        $('#' + navToToggle).addClass('visible')

        $('#main-nav .nav.first-level .active').removeClass('active')
        $(this).parent().addClass('active')
      }
    }
  })

  $('#main-nav .nav.second-level a').click(function (e) {
    if ($(this).parent().find('.third-level').length >= 1) {
      e.preventDefault()

      $(this).parent().find('.third-level').slideToggle()
      $(this).toggleClass('is-opened')
    }
  })

  $('#main-nav .back a').click(function (e) {
    e.preventDefault()
    var parentCategoryEl = $('[data-nav-to-toggle="' + $(this).closest('.nav').attr('id') + '"]').closest('.nav')
    if (parentCategoryEl.length >= 1) {
      $(this).closest('.nav').transition({
        opacity: 0,
        x: '100%'
      }, 300, function () {
        $(this).css('display', 'none')
        parentCategoryEl.css({
          opacity: 0,
          display: 'block',
          x: '-100%'
        }).transition({
          opacity: 1,
          x: 0
        }, 300)
      })
    }
  })

  $('#main-nav .context-mobile select').change(function () {
    window.location = $(this).find('option:selected').attr('val')
  })
})
