import $ from 'jquery';

$(document).ready(function () {
  if ($('.header').length >= 1) {
    $('.header').each(function () {
      if ($(this).find('.cell').length > 1) {
        var headerCarousel = $(this).flickity({
          prevNextButtons: false,
          adaptiveHeight: true,
          accessibility: false
        })
      }
    })
  }

  if ($('img').length > 0) {
    var imgTexts = $('img');
    var imgAlt;
    var imgTitle;
    imgTexts.each(function() {
      imgAlt = $(this).attr('alt');
      imgTitle = $(this).attr('title');
      if (imgAlt) {
        $(this).after('<div class="printOnly">' + imgAlt + '</div>');
      } else if (imgTitle) {
        $(this).after('<div class="printOnly">' + imgTitle + '</div>');
      }
    })
  }

});
