import $ from 'jquery';
function generateGoogleRouteLink(lat, lng, address) {
  let addressAddition = '';
  if (address) {
    addressAddition = 'saddr='+ encodeURI(address) +'&';
  }

  return 'https://www.google.com/maps?'+ addressAddition +'daddr='+ lat +','+ lng;
}

$(document).ready(function () {
  $('button.activateGoogleMap').on('click', function() {
    activateGoogleMap();
  });
  var activateGoogleMap = function () {

    document.getElementById("googleMapsScript").addEventListener('load', function() {

      if ($('.maps').length >= 1) {
        var zoom = $(window).width() < 1024 ? 2 : 3

        var map = new google.maps.Map($('#map')[0], {
          zoom: zoom,
          center: new google.maps.LatLng(googleMapsLat, googleMapsLng),
          mapTypeId: google.maps.MapTypeId.ROADMAP
        })

        var markers = [];
        var infoWindows = [];
        for (var i = 0; i < googleMapsLocations.length; i++) {
          const infowindow = new google.maps.InfoWindow({
            content: $(googleMapsLocations[i].info)[0]
          })

          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(googleMapsLocations[i].lat, googleMapsLocations[i].lng),
            map: map,
            lat: googleMapsLocations[i].lat,
            lng: googleMapsLocations[i].lng
          })

          markers.push(marker);
          infoWindows.push(infowindow);
          marker.addListener("click", function () {
            for (var j = 0; j < infoWindows.length ; j ++) {
              infoWindows[j].close();
            }

            infowindow.open(map, this);
          });
        }
      }

    });

    document.getElementById("googleMapsScript").src = document.getElementById("googleMapsScript").dataset.src;

  };

});
