import $ from 'jquery';

$(document).ready(function () {
    $('.mediathek').find('select').change(function (e) {
        $('.mediathek').find('form').submit();
    });

    $('.detail-link').click(function(e){
        e.preventDefault();

    });
    $('body').removeClass('slide-hide');
});