import $ from 'jquery';
$(document).ready(function () {
  if ($('.search').length >= 1) {
    $(window).on('resize.search', function () {
      if ($(this).width() >= 1024) {
        $('.search input')
      }

      $('.search').each(function () {
        var placeholderText = $(this).width() >= 1024 ? $(this).find('input').data('placeholder-desktop') : $(this).find('input').data('placeholder-mobile')
        $(this).find('input').attr('placeholder', placeholderText)
      })
    }).trigger('resize.search')

    var $elSearchfiel = $('.search').find('input'),
        language = $elSearchfiel.data('de'), //optional
        country = $elSearchfiel.data('DE');

    $elSearchfiel.autocomplete({
      minChars: 3,
      triggerSelectOnValidInput: false,
      lookup: function(term, done) {
        //update on every lookup because user may have changed the dropdown selection.
        $.getJSON(
            '/anymotion-elasticsearch/autocompletion',
            {
              q: term,
              language : $('html').attr('lang'),
              country: country
            },
            function(data) {
              var result = { suggestions : [] };
              if(data.length > 0) {
                $.each(data, function(index, suggestion) {
                  result.suggestions.push( {value : suggestion });
                });
              }
              done(result);
            });
      },
      onSelect: function(result) {
        $elSearchfiel.val(result.value);
        $elSearchfiel.parents('form').submit();
  }
    });
  }

})
