import $ from 'jquery';

$(document).ready(function () {

    var pressContactIframe = 'pressContactIframe';
    if ($('#'+pressContactIframe).length >= 1) {
        var remoteUrl = $('#'+pressContactIframe).data('url');
        var transport = new easyXDM.Socket({
            remote: remoteUrl,
            container: pressContactIframe,
            onMessage: function(message, origin) {
                this.container.getElementsByTagName('iframe')[0].style.height = message + 'px';
                this.container.getElementsByTagName('iframe')[0].scrolling = 'no';
             }
        });
    }
});