import { createSignal, createEffect } from '../libs/reactivity';

const initializeTabs = () => {
    const tabContainers = document.querySelectorAll('.tabs');

    tabContainers.forEach(container => {
        const tabButtons = container.querySelectorAll('.tabs-header a');
        const tabPanels = container.querySelectorAll('.tabs-content .tab');

        if (tabButtons.length === 0 || tabPanels.length === 0) {
            return;
        }

        const [active, setActive] = createSignal(tabButtons[0].dataset.tab);

        tabButtons.forEach(button => {
            button.addEventListener('click', function(e) {
                e.preventDefault();
                setActive(button.dataset.tab);
            })
        })

        createEffect(() => {
            const tab = active();
            tabButtons.forEach(button => {
                if (button.dataset.tab === tab) {
                    button.classList.add('active');
                } else {
                    button.classList.remove('active');
                }
            });

            tabPanels.forEach(panel => {
                if (panel.getAttribute('id') === tab) {
                    panel.classList.add('active');
                } else {
                    panel.classList.remove('active');
                }
            })
        });
    });

}

const initializeFileUpload = () => {
    const uploadContainers = document.querySelectorAll('.file-upload');

    uploadContainers.forEach(container => {
        const input = container.querySelector('input[type="file"]');
        const previewBox = container.querySelector('.previews');

        if (!input || !previewBox) {
            return;
        }

        initializeDropZone(container, input);

        const [files, setFiles] = createSignal([]);

        input.addEventListener('change', function(event) {
            event.preventDefault();
            const tempFiles = [...files()];
            Array.from(event.target.files).map(file => {
                tempFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    file: file,
                    extension: file.name.split('.').pop()?.toLowerCase(),
                    preview: file.type.startsWith('image') ? URL.createObjectURL(file) : null
                });
            });

            setFiles(tempFiles);

            const dt = new DataTransfer();
            tempFiles.map(f => dt.items.add(f.file));
            input.onchange = null;
            input.files = dt.files;
        });

        createEffect(() => {
            const length = files().length;

            if (length === 0) {
                previewBox.classList.add('hidden');
            } else {
                previewBox.classList.remove('hidden');
            }
        });

        createEffect(() => {
            previewBox.innerHTML = '';
            files().map(file => {
                const container = document.createElement('div');
                container.classList.add('preview');

                const thumbnail = document.createElement('div');
                thumbnail.classList.add('thumbnail');

                if (file.preview) {
                    thumbnail.innerHTML = `<img height="50" width="50" src="${file.preview}" alt="${file.name}" />`;
                } else {
                    thumbnail.innerHTML = `<i class="file-upload-icon icon-${file.extension} icon-${file.type}"></i>`;
                }

                const removeButton = document.createElement('button');
                removeButton.setAttribute('type', 'button');
                removeButton.innerHTML = '<i class="icon icon-trash"></i>';

                removeButton.addEventListener('click', function(e) {
                    e.preventDefault();
                    const newFiles = files().filter(f => f.name !== file.name);
                    const dt = new DataTransfer();
                    newFiles.map(f => dt.items.add(f.file));
                    input.onchange = null;
                    input.files = dt.files;
                    setFiles(newFiles);
                });

                const filename = document.createElement('span');
                filename.innerText = file.name;

                container.appendChild(thumbnail);
                container.appendChild(filename);
                container.appendChild(removeButton);
                previewBox.appendChild(container);
            });
        });
    });
}

const initializeDropZone = (container, input) => {

    const events = ['dragenter', 'dragover', 'dragleave', 'drop'];
    events.forEach(e => {
        container.addEventListener(e, function(ev) {
            ev.preventDefault();
            ev.stopPropagation();
        });
    });

    ['dragover', 'dragenter'].map(event => {
        container.addEventListener(event, function() {
            container.classList.add('dragover');
        }, false);
    });

    ['dragleave', 'drop'].map(event => {
        container.addEventListener(event, function() {
            container.classList.remove('dragover');
        }, false);
    });

    container.addEventListener('drop', function(event) {
        const files = event.dataTransfer.files;
        input.files = files;

        const ev = new Event('change', { bubbles: true });
        input.dispatchEvent(ev);
    });
}

initializeTabs();
initializeFileUpload();

