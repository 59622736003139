import $ from 'jquery';
$(document).ready(function () {

    $('.teasers-white .multi').each(function(){
        var html = $(this).html();
        html = html.replaceAll(' / ', ' <span class="green">/</span> ');
        html = html.replaceAll('|', '<span class="green">|</span>');
        $(this).html(html);
    });

  $('.teasers-white .nav-tab a').click(function (e) {
    e.preventDefault()
    $(this).closest('.nav-tab').find('li').removeClass('active')
    $(this).parent().addClass('active')

    var toToggle = $(this).data('to-toggle')
    $(this).closest('.box').find('.to-toggle').addClass('hidden')
    $(this).closest('.box').find('.' + toToggle).removeClass('hidden')
  });
})
