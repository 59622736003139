import $ from 'jquery';

$(document).ready(function () {
  if ($('.upgrades').length >= 1) {
    var chooseSystem = function (system, el) {
      $(el).closest('.upgrades').find('.select-wrapper').hide()
      $(el).closest('.upgrades').find('#select-wrapper-' + system).show()

      $(el).closest('.upgrades').find('.upgrade-wrapper').hide()
      $(el).closest('.upgrades').find('.select-upgrade button').text($(el).closest('.upgrades').find('.select-upgrade button').data('text'))
    }

    $('.upgrades .select-system select').unbind().change(function (e) {
      chooseSystem($(this).find(':selected').attr('value'), this)
      $(this).closest('.select').find('button').text($(this).find(':selected').text())
    })

    $('.upgrades .select-system a').unbind().click(function (e) {
      e.preventDefault()
      chooseSystem($(this).data('value'), this)
      $(this).closest('.select').find('button').trigger('click').text($(this).text())
    })

    var chooseUpgrade = function (upgrade, el) {
      $(el).closest('.upgrades').find('.upgrade-wrapper').hide()
      $(el).closest('.upgrades').find('#upgrade-wrapper-' + upgrade).show()
    }

    $('.upgrades .select-upgrade select').unbind().change(function (e) {
      chooseUpgrade($(this).find(':selected').attr('value'), this)
      $(this).closest('.select').find('button').text($(this).find(':selected').text())
    })

    $('.upgrades .select-upgrade a').unbind().click(function (e) {
      e.preventDefault()
      chooseUpgrade($(this).data('value'), this)
      $(this).closest('.select').find('button').trigger('click').text($(this).text())
    })
  }
})
