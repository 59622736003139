import $ from 'jquery';

$(document).ready(function () {
  if ($('.form').length >= 1) {
    $('.form .input-wrapper input, .form .input-wrapper textarea').each(function () {
      if ($(this).val().length > 0) {
        $(this).parent().addClass('label-above')
      } else {
        $(this).parent().removeClass('label-above')
      }
    })

    $('.form .input-wrapper input, .form .input-wrapper textarea').focus(function () {
      $(this).parent().addClass('label-above')
    }).blur(function () {
      if ($(this).val().length === 0) {
        $(this).parent().removeClass('label-above')
      }
    })
  }

  $('.select button').click(function (e) {
    e.preventDefault()
    $(this).closest('.select').toggleClass('active')
  })

  $('.select-shadow').click(function (e) {
    e.stopPropagation()
    $(this).parent().find('.select').toggleClass('active')
  })

  $('.select select').on('change', function () {
    window.location = $(this).find('option:selected').attr('value')
  })

})
