import $ from 'jquery';
$(document).ready(function () {
  if ($('.quotes').length >= 1) {
    var quotesCarousels = [],
      quotesCells = []

    $('.quotes').each(function () {
      var carousel = $(this).find('.carousel').flickity({
        prevNextButtons: false
      })

      carousel.on('scroll.flickity', function (event, progress) {
        var progress = progress * 100

        quotesCells.forEach(function (cell) {
          if (progress > cell.start && progress <= cell.end) {
            var cellDistance = Math.abs(progress - cell.pos) / halfRange

            if (progress < cell.pos) {
              cell.headerEl.css({
                'x': (cellDistance * 300) + 'px',
                opacity: 1 - cellDistance * 1.4
              })
              cell.textEl.css({
                'x': (cellDistance * 150) + 'px',
                opacity: 1 - cellDistance * 2
              })
            } else {
              cell.headerEl.css({
                'x': (cellDistance * -300) + 'px',
                opacity: 1 - cellDistance * 1.4
              })
              cell.textEl.css({
                'x': (cellDistance * -150) + 'px',
                opacity: 1 - cellDistance * 2
              })
            }
          }
        })
      })

      var length = $(this).find('.cell').length,
        halfRange = (100 / (length - 1)) / 2

      $(this).find('.cell').each(function () {
        var pos = ($(this).index()) * (100 / (length - 1))

        quotesCells.push({
          el: $(this),
          headerEl: $(this).find('h3'),
          textEl: $(this).find('p'),
          pos: pos,
          start: pos - (100 / (length - 1)) / 2,
          end: pos + (100 / (length - 1)) / 2
        })

        quotesCarousels.push(carousel)
      })
    })
  }
})
