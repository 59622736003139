import $ from 'jquery';

$(document).ready(function () {
  if ($('.image-slider').length >= 1) {
    var imageSliderCarousels = []
    $('.image-slider').each(function () {
      var imageSliderCarousel = $(this).find('.carousel').flickity({
        prevNextButtons: false,
        adaptiveHeight: true,
        accessibility: false
      })

      imageSliderCarousels.push(imageSliderCarousel)
    })
  }

  if ($('.image-video-gallery').length >= 1) {
    let options = {
      lazyLoad: 2,
      wrapAround: true,
      adaptiveHeight: false,
      prevNextButtons: true,
      pageDots: false,
      draggable: true
    };

    $('.image-video-gallery').each(function(i, container) {
      $(this).find('.carousel').flickity(options);

      let $container = $(container);
      let $slider = $container.find('.carousel');
      let videos = $container.find('video');
      let flkty = $slider.data('flickity');
      let slideCount = flkty.slides.length;
      let $pagers = $container.find('.thumbs .pager');
      for (i = 0; i < slideCount; i++) {
        let elem = flkty.slides[i];
        let activeSlide = '';
        if (elem.cells[0].element.classList.contains('is-selected'))  {
          activeSlide = elem.cells[0].element.dataset.index;
          $(this).find('#' + activeSlide).addClass('is--active');
        }
      }
      let $pager = $pagers.find('.item');

      $slider.on('select.flickity', function() {

        $pager.filter('.is--active').removeClass('is--active');
        $pager.eq(flkty.selectedIndex).addClass('is--active');
        // if videos
        if (videos.length > 0) {
          for (y = 0; y < videos.length; y++) {
            videos[y].pause();
          }
        }

      });

      $pagers.on('click', '.item', function() {
        let index = $(this).index();
        $slider.flickity('select', index);
      });


    });
  }
})
