import $ from 'jquery';
$(document).ready(function () {
  if ($('.press-kit').length >= 1) {
    $('.press-kit .toggle-accordion').click(function (e) {
      e.preventDefault()
      $(this).toggleClass('opened')
      $(this).parent().parent().find('.dropdown-wrapper').slideToggle(150)
    })
  }
})
