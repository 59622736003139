import $ from 'jquery';

$(document).ready(function () {
  if ($('.keyvisual').length >= 1) {
    $('.keyvisual').each(function () {
      var keyvisualCells = []

      var keyvisualCarousel = $(this).flickity({
        prevNextButtons: false,
        adaptiveHeight: true,
        accessibility: false
      })

      if ($(this).hasClass('count')) {
        let sliderLength =  $(this).find('.cell .cell-inner').length;
        if (sliderLength < 2) {
          $(this).addClass('low');
          $(this).removeClass('is-draggable');
          $(this).flickity('destroy');
        }
      }

      keyvisualCarousel.on('scroll.flickity', function (event, progress) {
        var progress = progress * 100

        keyvisualCells.forEach(function (cell) {
          if (progress > cell.start && progress <= cell.end) {
            var cellDistance = Math.abs(progress - cell.pos) / halfRange

            if (progress < cell.pos) {
              cell.boxEl.css({
                'opacity': 1 - cellDistance
              })
              cell.headerEl.css({
                'y': (1 - cellDistance * 30) + 'px',
                scale: 0.8 + ((1 - cellDistance) / 5)
              })
              cell.textEl.css('y', (cellDistance * 30) + 'px')
              cell.buttonEl.css('y', (cellDistance * 30) + 'px')
            } else {
              cell.boxEl.css({
                'opacity': 1 - cellDistance
              })
              cell.headerEl.css({
                'y': (1 - cellDistance * 30) + 'px',
                scale: 0.8 + ((1 - cellDistance) / 5)
              })
              cell.textEl.css('y', (1 - cellDistance * 30) + 'px')
              cell.buttonEl.css('y', (1 - cellDistance * 30) + 'px')
            }
          }
        })
      })

      var length = $('.keyvisual .cell').length,
        halfRange = (100 / (length - 1)) / 2

      $('.keyvisual .cell').each(function () {
        var pos = ($(this).index()) * (100 / (length - 1))

        keyvisualCells.push({
          el: $(this),
          boxEl: $(this).find('.box'),
          headerEl: $(this).find('h1'),
          textEl: $(this).find('p'),
          buttonEl: $(this).find('.btn'),
          pos: pos,
          start: pos - (100 / (length - 1)) / 2,
          end: pos + (100 / (length - 1)) / 2
        })
      })
    })
  }
})
