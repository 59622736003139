import $ from 'jquery';
$(document).ready(function () {
  if ($('.teasers-press').length >= 1) {
 
    $('.teasers-press select').change(function(e) {
      e.preventDefault();
      let jobList = $(this).parents('#jobList');
      if (jobList.length > 0) {
        let selectedLabel = this.options[0].text + ': ' + this.options[this.selectedIndex].text;
        dataLayerPush(selectedLabel);
        setTimeout(() => {
          triggerSubmit($(this));
        }, 500);
      } else {
        $(this).closest('form').trigger('submit');
      }
    })
  }

});

function dataLayerPush(selectedLabel) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'jobFilterSelection',
    selectedElement: selectedLabel
  });
}

function triggerSubmit(elem) {
  elem.closest('form').trigger('submit');
}
