import $ from 'jquery';
$(document).ready(function () {
  if ($('.sidebar-shares').length >= 1) {
    $('.sidebar-shares .nav-tab a').click(function (e) {
      e.preventDefault()
      $(this).closest('.nav-tab').find('li').removeClass('active')
      $(this).parent().addClass('active')

      var toToggle = $(this).data('to-toggle')
      $(this).closest('.box').find('.to-toggle').addClass('hidden')
      $(this).closest('.box').find('.' + toToggle).removeClass('hidden')
    })
  }
})
