import $ from 'jquery';

$(document).ready(function () {
  if ($('#mobile-wrapper').length >= 1 && $('#desktop-wrapper').length >= 1) {
    var els = []
    $('[data-mobile-position]').each(function () {
      els.push({
        el: $(this).clone(),
        order: $(this).data('mobile-position')
      })
    })

    els.sort(function (a, b) {
      return a.order - b.order
    })

    els.forEach(function (val) {
      $('#mobile-wrapper').append(val.el)
    })
  }
})
