import $ from 'jquery';
$(document).ready(function () {
  if ($('.products').length >= 1) {
    $('.products nav button').click(function (e) {
      e.preventDefault()
      $(this).closest('ul').find('.active').removeClass('active')
      $(this).parent().addClass('active')

      var categoryToShow = $(this).data('category-to-show')

      if (categoryToShow === 'all') {
        $(this).closest('.products').find('.box-wrapper').show()
      } else {
        $(this).closest('.products').find('.box-wrapper').hide()
        $(this).closest('.products').find('[data-category="' + categoryToShow + '"]').show()
        console.log('[data-category="' + categoryToShow + '"]')
      }
    })
  }
})
