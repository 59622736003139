import $ from 'jquery';

$(document).ready(function () {
  if ($('.accordion').length >= 1) {
    $('.accordion-header').click(function (e) {
      e.preventDefault()

      $(this).toggleClass('opened')

      if ($(this).hasClass('opened')) {
        $(this).parent().find('.accordion-body').slideDown(150)
      } else {
        $(this).parent().find('.accordion-body').slideUp(150)
      }
    })
  }
})
