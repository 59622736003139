import $ from 'jquery';

$(document).ready(function () {

  // choose-contact-person
  if($('.choose-contact-person').length > 0) {
    var showContact;
    $('#chooser').on('change', function() {
      showContact = $(this).val();
      if(!$('#' + showContact).hasClass('show')) {
        $('.choose-contact-person .result').removeClass('show');
        if(showContact != 'start') {
          $('#' + showContact).addClass('show');
        }
      }
    });
  }


});
