import $ from 'jquery';
$(document).ready(function () {

    var accompaniment = $('#form_registration_form_accompaniment');
    var accompanimentPerson = $('#accompanimentPerson');
    if (accompaniment.length >= 1) {
        accompaniment.on('change', function(){
            if($(this).prop('checked') === true) {
                accompanimentPerson.show();
            }  else {
                accompanimentPerson.hide();
            }
        });
    }
});