import $ from 'jquery';
$(document).ready(function () {
  let jobSwitcher = $('#jobSwitcher');
  if (jobSwitcher.length >= 1) {
    let tabs = $('#jobSwitcher ul li a');
    tabs.each(function() {
      $(this).on('click', function (e) {
        e.preventDefault();
        if (!$(this).hasClass("is-active")) {
          $(tabs).removeClass('is-active');
          $(tabs).attr('aria-selected',false);
          $(tabs).attr('tabindex','-1');
          $(this).addClass('is-active');
          $(this).attr('aria-selected',true);
          $(this).attr('tabindex','0');
          if ($(this).data('tabs-target') === 'tilesView') {
            $('#listView').removeClass('is-active');
            $('#listView').attr('aria-hidden', 'true');
            $('#tilesView').addClass('is-active');
            $('#tilesView').removeAttr('aria-hidden');
            $('form input[name ="tilesActiveField"]').val('true');
          } else {
            $('#tilesView').removeClass('is-active');
            $('#tilesView').attr('aria-hidden', 'true');
            $('#listView').addClass('is-active');
            $('#listView').removeAttr('aria-hidden');
            $('form input[name ="tilesActiveField"]').val('false');
          }
        }
      })
    })
  }
})
