import $ from 'jquery';
$(document).ready(function () {
  if ($('.press-dates').length >= 1) {
    $('.press-dates .tabs a').click(function (e) {
      e.preventDefault()
      $(this).closest('.tabs').find('li').removeClass('active')
      $(this).parent().addClass('active')

      $(this).closest('.press-dates').find('.tab-wrapper').addClass('hidden')
      $(this).closest('.press-dates').find('#tab-wrapper-' + $(this).data('tab')).removeClass('hidden')
    })

    $('.press-dates select').change(function (e) {
      var val = $(this).find(':selected').attr('value'),
        text = $(this).find(':selected').text(),
        table = $('.' + $(this).closest('.filter-wrapper').data('for') );

      $(this).parent().find('.select-button').text(text)

      if (val === 'all') {
        table.find('tbody tr').show()
      } else {
        table.find('tbody tr').each(function () {
          if ($(this).data('type') === val) {
            if ((val === 'cid_archive') && ($(window).width() < 768)) {
              $(this).css('display', 'block');
            } else if (val === 'cid_archive') {
              $(this).css('display', 'table-row');
            } else {
              $(this).show()
            }
          } else {
            $(this).hide()
          }
        })
      }
    })

  }
})
