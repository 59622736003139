import $ from 'jquery';
$(document).ready(function () {

  // sticky fly out
  var flyTab;
  var winHeight;
  var checkHeight;

  $('#sticky-contact .list .partner a').on('click', function() {
    flyTab = '#tab' + $(this).parent().attr('class');

    if (!$(this).parent().hasClass('active')) {
      winHeight = $(window).height();
      $('#sticky-contact .list li').removeClass('active');
      $(this).parent().addClass('active');
      $('.fly-container').removeClass('open');
      $(flyTab).addClass('open');
      $('html').addClass('not-scrollable');
    } else {
      closeFlyout();
      $('html').removeClass('not-scrollable');
    }

    return false;

  });

  $('.fly-container .close').on('click', function() {
    closeFlyout();
    $('html').removeClass('not-scrollable');
  });

  $('#toggle-nav').on('click', function() {
    closeFlyout();
  });

  function closeFlyout() {
    $('.fly-container').removeClass('open');
    $('#sticky-contact .list li').removeClass('active');
  }

  // to top
  var hideShow = 300;//height on which the button will show
  var fadeInTime = 300;//how slow/fast you want the button to show
  var fadeOutTime = 300;//how slow/fast you want the button to hide
  var scrollSpeed = 300;//how slow/fast you want the button to scroll to top. can be a value, 'slow', 'normal' or 'fast'

  // Show or hide the sticky footer button
  $(window).scroll(function() {

    if ($(window).scrollTop() >= hideShow) {
      $("#toTop").fadeIn(fadeInTime);
    } else {
      $("#toTop").fadeOut(fadeOutTime);
    }

  });

  // Animate the scroll to top
  $("#toTop").click(function() {
    $("html, body").animate({scrollTop:0}, scrollSpeed);
    return false;
  });

});
