import $ from 'jquery';
$(document).ready(function () {
  if ($('.teasers-story').length >= 1) {
    $(window).on('resize.teasersStory', function () {
      if ($(this).width() >= 768) {
        $('.teasers-story .cell').each(function () {
          $(this).css('background-image', 'url(' + $(this).data('desktop-bg') + ')')
        })
      } else {
        $('.teasers-story .cell').attr('style', null)
      }
    }).trigger('resize.teasersStory')

    var teasersStoryCarousels = []

    $('.teasers-story').each(function () {
      if ($(this).find('.cell').length > 1) {
        var teasersStoryCarousel = $(this).find('.carousel').flickity({
          prevNextButtons: false,
          adaptiveHeight: true,
          accessibility: false
        })

        teasersStoryCarousels.push(teasersStoryCarousel)
      }
    })
  }
})
