import $ from 'jquery';

$(document).ready(function () {
  var headerEl = $('#header')
  var scrollTop = 0
  var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)

  $(window).on('scroll.header', function (e) {
    if ($(this).scrollTop() > 5) {
      headerEl.addClass('small')
    } else {
      headerEl.removeClass('small')
    }
  }).trigger('scroll.header')

  $('#toggle-nav').click(function (e) {
    e.preventDefault()

    if (!$(this).hasClass('nav-open')) {
      scrollTop = $(window).scrollTop()

      if (isIOS && false) {
        $(window).on('scroll', function (e) {
          if ($(this).scrollTop() != scrollTop) {
            $('html, body').scrollTop(scrollTop)
          }
        })
      } else {
        $('main').css('y', -1 * scrollTop)
        $('html').addClass('not-scrollable')
      }

      $(this).addClass('nav-open')
      openNav()
    } else {
      if (isIOS && false) {
        $(window).off('scroll')
      } else {
        $('main').css('y', '0')
        $('html').removeClass('not-scrollable')
        $('html, body').scrollTop(scrollTop)
      }

      $(this).removeClass('nav-open')
      closeNav()
    }
  })

  /* Bugfix: IE animation bug */
  $('#toggle-nav').addClass('nav-open');
  setTimeout(function(){
    $('#toggle-nav').removeClass('nav-open');
  });

  $('#context-nav .context-language').hover(function () {
    //openLanguage();
    $('#nav-language').css({
      opacity: 0,
      display: 'block'
    }).transition({
      opacity: 1
    }, 200)
  }, function () {
    //closeLanguage();
    $('#nav-language').transition({
      opacity: 0,
      display: 'none'
    }, 200)
  });

  $('#context-nav .context-language >a').click(function (e) {
    e.preventDefault();

    $('#nav-language').css({
      opacity: 0,
      x: '-50%',
      y: '-10px',
      display: 'block'
    }).transition({
      x: '-50%',
      y: 0,
      opacity: 1
    }, 300, function () {
      $(this).attr('style', null).addClass('visible')
    })
  })

  $('#context-nav .nav-language-shadow').click(function (e) {
    e.stopPropagation();

    $('#nav-language').transition({
      opacity: 0,
      x: '-50%',
      y: '-10px'
    }, 300, function () {
      $(this).removeClass('visible').attr('style', null)
    })
  })

  $('#header .context-search a').click(function (e) {
    e.preventDefault()

    setTimeout(function () {
      scrollTop = $(window).scrollTop()
      $('main').css('y', -1 * scrollTop)
      $('html').addClass('not-scrollable')
    }, 600)

    $('#header-search-close').css({
      left: $(this).offset().left
    })

    $('#header-search').css({
      opacity: 0,
      display: 'block'
    }).transition({
      opacity: 1
    }, 600, function () {
      $(this).find('input').focus()
    })
  })

  $('#header-search-close').click(function (e)  {
    e.preventDefault()

    $('#header-search input').blur()

    $('#header-search').transition({
      opacity: 0
    }, 600, function () {
      $(this).css({
        display: 'none'
      })
    })

    $('main').css('y', '0')
    $('html').removeClass('not-scrollable')
    $('html, body').scrollTop(scrollTop)
  })

  $('.second-level, .third-level').find('a').click(function (e) {
    var navigationLink = $(this).attr('href');

    if (navigationLink.indexOf("#") !== -1 && navigationLink !== '#') {

      closeNav();
      $('#header-search input').blur()

      $('#header-search').transition({
        opacity: 0
      }, 600, function () {
        $(this).css({
          display: 'none'
        })
      });
      $('main').css('y', '0')
      $('html').removeClass('not-scrollable')
      $('#toggle-nav').removeClass('nav-open');
    }
  });

})

function openNav () {
  $('#main-nav').transition({
    display: 'block'
  }, 10, function () {
    $(this).addClass('visible')

    $('#main-nav .main-nav-bottom').css({
      opacity: 0,
      y: 55
    })

    $(window).trigger('resize.main-nav')

    $('#main-nav .main-nav-bottom').transition({
      opacity: 1,
      y: 0,
      delay: 300
    }, 600, 'easeOutExpo')

    $('#main-nav .main-nav-top').css({
      scale: 0.9
    }).transition({
      scale: 1
    }, 600)
  })
}

function closeNav () {
  $('#main-nav').removeClass('visible')

  $('#main-nav .main-nav-top').transition({
    scale: 0.9
  }, 600, function () {
    $('#main-nav').css('display', 'none')
  })
}
