import $ from 'jquery';

window.addEventListener('CookiebotOnTagsExecuted', function (e) {
  if (!Cookiebot.consent.marketing) {
    if ($('.video-wrapper').length > 0) {
      var nocookie = $('.video-wrapper iframe.cookieconsent-optin-marketing');
      nocookie.each(function(){
        $(this).parents('.video-wrapper').addClass('forbidden');
      })
    }
  }

}, false);
window.addEventListener('CookiebotOnDecline', function (e) {
  if (!Cookiebot.consent.marketing) {
    if ($('.video-wrapper').length > 0) {
      var nocookie = $('.video-wrapper iframe.cookieconsent-optin-marketing');
      nocookie.each(function(){
        $(this).parents('.video-wrapper').addClass('forbidden');
      })
    }
  }

}, false);
window.addEventListener('CookiebotOnAccept', function (e) {
  if (Cookiebot.consent.marketing) {
    if ($('.video-wrapper').length > 0) {
      var nocookie = $('.video-wrapper iframe.cookieconsent-optin-marketing');
      nocookie.each(function(){
        $(this).parents('.video-wrapper').removeClass('forbidden');
      })
    }
  }
}, false);
