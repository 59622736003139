import $ from 'jquery';
$(document).ready(function () {
  var teasersCarousels = []

  $(window).on('resize.teasers', function () {
    teasersCarousels.forEach(function (val) {
      val.flickity('destroy')
    })

    teasersCarousels = []

    $('.teasers .box').css('height', '100%')

    if ($(this).width() < 1024) {
      $('.teasers').each(function () {
        var carousel = $(this).find('.xs-carousel').flickity({
          prevNextButtons: true,
          pageDots: false
        })

        teasersCarousels.push(carousel)

                // Set correct height since slider does not work with display:flex
        var maxHeight = 0

        $(this).find('.box').each(function () {
          maxHeight = Math.max(maxHeight, $(this).outerHeight())
        })

        $(this).find('.box').css('height', maxHeight)
      })
    }
  }).trigger('resize.teasers')
})

document.addEventListener('gesturestart', function (e) {
  e.preventDefault()
})
